<template>
    <page-content>

        <common-table
                ref="table"
                path="web/beian/push"
                :columns="columns">

            <template slot="search">
                <a-button  ghost type="primary" @click="add">新增</a-button>
            </template>

            <template slot="phone-push" slot-scope="{record}">
                <a-icon type="check" v-if="record.phonePush"/>
            </template>

            <template slot="email-push" slot-scope="{record}">
                <a-icon type="check" v-if="record.emailPush"/>
            </template>

            <template slot="operation" slot-scope="{record}">
                <action-edit @click="edit(record)"></action-edit>
                <action-delete @click="remove(record)"></action-delete>
            </template>

        </common-table>

        <PushEdit ref="editDrawer" @success="getList"></PushEdit>

    </page-content>
</template>
<script>
    import PushEdit from "./PushEdit";
    export default {
        components: {PushEdit},
        data() {
            return {
                queryParams:{
                    status:null
                }
            }
        },

        computed: {
            columns() {
                return [
                    {
                        title: '姓名',
                        dataIndex: 'name',
                    },
                    {
                        title: '手机号',
                        dataIndex: 'phone'
                    },
                    {
                        title: '邮箱',
                        dataIndex: 'email',
                    },
                    {
                        title: '短信推送',
                        dataIndex: 'phonePush',
                        scopedSlots: {customRender: 'phone-push'}
                    },
                    {
                        title: '邮件推送',
                        dataIndex: 'emailPush',
                        scopedSlots: {customRender: 'email-push'}
                    },
                    {
                        title: '创建时间',
                        dataIndex: 'createdAt'
                    },
                    {
                        title: '操作',
                        dataIndex: 'status',
                        scopedSlots: {customRender: 'operation'}
                    }
                ]
            }
        },
        methods: {
            add() {
                this.$refs.editDrawer.show()
            },
            edit(record) {
                this.$refs.editDrawer.show(record)
            },
            getList() {
                this.$refs.table.getData()
            }

        }
    }
</script>
<style lang="less" scoped>

    .news-logo {
        width: 60px;
        height: 60px;
    }
</style>
